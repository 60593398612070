export default {
    methods: {
        sortDefinitions(definitions) {
            definitions.sort(function (a, b) {
                let idxA = a.lastIndexOf('#');
                let idxB = b.lastIndexOf('#');
                if (idxA > -1 && idxB > -1 && a.substring(0, idxA) === b.substring(0, idxB)) {
                    if (parseInt(a.substring(idxA + 1)) < parseInt(b.substring(idxB + 1))) return -1;
                    if (parseInt(a.substring(idxA + 1)) > parseInt(b.substring(idxB + 1))) return 1;
                } else {
                    if (a < b) return -1;
                    if (a > b) return 1;
                }
                return 0;
            });

            return definitions;
        },
        getDefinitionsToRenumber(definitions, definitionNamesToProcess) {
            let sortedDefinitions = this.sortDefinitions(definitions);
            let expectedNumber;
            let gapsInDefinition = [];
            let definitionsToRenumber = [];
            let previousDefinition = null;
            let currentDefinitionName = null;

            sortedDefinitions.forEach(definition => {
                if (previousDefinition !== definition) {
                    let definitionName = definition.substring(0, definition.lastIndexOf("#"));
                    let definitionNumber = parseInt(definition.substring(definition.lastIndexOf("#") + 1));

                    if (!definitionNamesToProcess || definitionNamesToProcess.includes(definitionName)) {
                        previousDefinition = definition;

                        if (definitionName !== currentDefinitionName) {
                            gapsInDefinition.forEach(gap => {
                                if (gap.old_number > 0) {
                                    definitionsToRenumber.push(gap);
                                }
                            });
                            gapsInDefinition = [];
                            currentDefinitionName = definitionName;
                            expectedNumber = 1;
                        }

                        if (definitionNumber !== expectedNumber) {
                            for (; expectedNumber < definitionNumber; expectedNumber++) {
                                gapsInDefinition.push({
                                    name: definitionName,
                                    old_number: 0,
                                    new_number: expectedNumber,
                                });
                            }
                        }

                        if (gapsInDefinition.length > 0) {
                            for (let i = gapsInDefinition.length - 1; i > 0; i--) {
                                if (gapsInDefinition[i - 1].old_number > gapsInDefinition[i].new_number) {
                                    gapsInDefinition[i].old_number = gapsInDefinition[i - 1].old_number;
                                } else {
                                    gapsInDefinition[i].old_number = 0;
                                }
                            }
                            gapsInDefinition[0].old_number = definitionNumber;
                        }

                        expectedNumber++;
                    }
                }
            });

            gapsInDefinition.forEach(gap => {
                if (gap.old_number > 0) {
                    definitionsToRenumber.push(gap);
                }
            });

            return definitionsToRenumber;
        }
    }
}